<template>
  <div class="warp-bg">
    <div class="title"><p>{{ marker.name }}</p></div>
    <div v-for="(item,index) in lists" :key="index" class="listHot">
      <div class="list-title">
        <h1 @click="handleBusinessShow(item.uuid)">{{ item.business_name }}</h1>
      </div>
      <div v-for="good in item.goods" :key="good.spu_id" class="list">
        <div class="list-img">
          <p><router-link :to="'/orderShow/'+good.spu_id+'/'+good.sku_id"><img :src="good.images"></router-link></p>
        </div>
        <div class="list-txt">
          <h2><router-link :to="'/orderShow/'+good.spu_id+'/'+good.sku_id">{{ good.goods_name }}</router-link></h2>
          <p>{{ good.details }}</p>
          <h5><span v-for="(tag,i) in good.tags" :key="i">{{ tag }}</span></h5>
          <h4>
            <span class="rateing">{{ good.ratings.toFixed(1) }} <van-rate v-model="good.ratings" allow-half void-icon="star" readonly size="12" gutter="1" color="#FFA717" void-color="#D8D8D8" /></span>
          </h4>
          <h3>¥<span>{{ good.price }}</span>起</h3>
        </div>
      </div>
    </div>
    <tab-bar />
    <Footer />
  </div>
</template>

<script>
import { List } from 'vant'
import { getPoi } from '@/api/mapV2'
import TabBar from '@/components/tabBar.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'OrderList',
  components: {
    TabBar,
    [List.name]: List,
    Footer
  },
  data() {
    return {
      markerId: '',
      lists: [],
      marker: ''
    }
  },
  created() {
    this.markerId = this.$route.params && this.$route.params.id
    this.orderList()
  },
  methods: {
    handleBusinessShow(id) {
      this.$router.push({ path: `/businessShow/${id}` })
    },
    async orderList() {
      const data = {
        id: this.markerId,
        lng: this.$store.state.location.lng,
        lat: this.$store.state.location.lat
      }
      const res = await getPoi(data)
      this.marker = res.payload.list[0]
      this.lists = this.marker.associated_business
      this.marker.associated_business.forEach(business => {
        console.log(business)
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.title{
  width :100%;
  padding: .2rem 2% 0;
  height :.9rem;
  p{
    height :.67rem;
    line-height :.6rem;
    color :@black3;
    font-size :.34rem;
  }
}
.list-title{ width :100%; position: relative; padding :0.2rem 0.2rem 0;
  h1{ font-size :0.34rem; line-height :.6rem; color :#333; padding-bottom :0.15rem; border-bottom :1px solid #eee; margin-bottom: 0.15rem; font-weight: bold; }
  p{ font-size: 0.26rem; line-height: 0.4rem; display: flex; color: #333; padding: 0.05rem 0;
    img{ width: auto; height: 0.36rem; margin-right: 0.1rem; margin-top: 0.02rem;}
  }
  span{ font-size: 0.24rem; padding: 0 0.2rem; border-radius: 0.5rem; margin-right: 0.1rem; color: #fff;}
  .show-col1{ background: #6F85FF;}
  .show-col2{ background: #FFA717;}
  h3{ position: absolute; right: 0.2rem; bottom: 0.1rem; line-height: 0.4rem; font-size: 0.24rem; color: #333;
    img{ width: auto; height: 0.4rem; margin-right: 0.05rem;}
  }
}

.listHot{
  width: 100%;
  height :2rem;
  .autoHeight();
  padding :0 2% 0.2rem;
  background: @white;
  margin-bottom: 0.2rem;
}
.list{
  width: 100%;
  height :2rem;
  .autoHeight();
  padding :0.1rem 4%;
  background: @white;
}
.list-img{ width :40%; position: relative; float: left;
  p img{
    width :100%;
    height :1.8rem;
    border-radius: 5px;
  }
}
.list-txt{
  width: 60%;
  min-height :1.8rem;
  position : relative;
  padding-left:0.2rem;
  float: left;
  img{ width: auto; height: 0.3rem;}
  h2{
    font-size :0.3rem;
    line-height : 0.5rem;
    .LimitWord();
    -webkit-line-clamp :1;
    color :@black3;
    a{ color :@black3}
  }
  p{
    font-size :0.2rem;
    line-height : 0.3rem;
    margin-top: 0.1rem;
    .LimitWord();
    -webkit-line-clamp :1;
    color :#999;
  }
  h4{ width: 100%; display: flex; position: absolute; bottom: 0; left: 0.2rem;
    .rateing{ width: 60%; font-size: 0.28rem;}
    .distance{ width: 40%; color :@grey6; font-size : 0.2rem; text-align: right;}
    span{ font-size: 0.3rem; line-height:  0.3rem; color: #FFA717; margin-right: 0.1rem; font-family: Arial;}
  }
  h3{ position: absolute; right: 0; bottom: 0; font-size: 0.2rem; color: #999; line-height: 0.34rem;
    span{ color: #FF4545; font-size: 0.34rem; padding: 0 0.05rem;}
  }
  h5{ margin-top: 0.05rem;
    span{ font-size: 12px; line-height: 12px; border:1px rgba(111, 133, 255, 0.34) solid; border-radius: 0.4rem; padding: 0 0.2rem; color: rgba(111, 133, 255, 1); margin-right: 5px;}
  }
}
</style>
