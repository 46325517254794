<template>
  <div class="footer">
    <p>技术支持：爱知世元</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.footer{ width: 100%; padding: 10px 0; margin-top: 0.2rem;
  p{ text-align: center; font-size: 12px; line-height:24px; color: #999;}
}
</style>
